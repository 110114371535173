import { PropsWithChildren } from "react";
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from "./dropdown-menu";
import { Button } from "./button";
import { ChevronDown } from "lucide-react";
import { useTranslation } from "react-i18next";

type DropdownMultiSelectProps = PropsWithChildren<{
  options: Array<{ label: string; value: string }>;
  value: string[];
  onChange: (value: string[]) => void;
}>;

export function DropdownMultiSelect(props: DropdownMultiSelectProps) {
  const { t } = useTranslation();
  const id = Math.random();
  const value = Array.isArray(props.value) ? props.value : [];
  const valueLabels = props.options.filter((opt) => value.includes(opt.value)).map((opt) => opt.label);
  const visibleLabels = valueLabels
    .slice(0, 2)
    .concat(
      ...(valueLabels.length > 2
        ? [t("common_dropdownMultiSelect_more", "+ {{count}} more", { count: valueLabels.length - 2 })]
        : [])
    );

  return (
    <DropdownMenu modal={true}>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">
          {value?.length ? (
            <span>{visibleLabels.join(", ")}</span>
          ) : (
            <span>{t("common_dropdownMultiSelect_empty", "Select ...")}</span>
          )}
          <ChevronDown className="ml-3" size={16} strokeWidth={1.2} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="max-h-96 overflow-y-auto">
        {props.options.map((opt) => (
          <DropdownMenuCheckboxItem
            key={`option-${id}-${opt.value}`}
            onClick={(e) => {
              if (!value.includes(opt.value)) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                props.onChange?.([...value, opt.value] as any);
              } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                props.onChange?.([...value.filter((v) => v !== opt.value)] as any);
              }
              e.stopPropagation();
              e.preventDefault();
            }}
            checked={value.includes(opt.value)}
          >
            {opt.label}
          </DropdownMenuCheckboxItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
