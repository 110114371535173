import { cn } from "@pulso/components/lib/utils";
import { Link } from "react-router-dom";

type MenuItem = { title: string; description: string; href: string };

export type SidebarMenuProps = {
  items: MenuItem[];
  activeItem: MenuItem | undefined;
};
export function SidebarMenu(props: SidebarMenuProps) {
  return (
    <div className="w-40 min-w-32 h-full py-5 px-2 flex flex-col gap-1 text-sm bg-secondary-dark overflow-y-auto">
      {props.items.map((item) => (
        <Link key={item.href} to={item.href}>
          <div
            className={cn(
              "rounded-md py-2 px-4 text-secondary-foreground",
              item === props.activeItem && "bg-secondary-darker",
              item !== props.activeItem && "hover:bg-secondary-darker"
            )}
          >
            {item.title}
          </div>
        </Link>
      ))}
    </div>
  );
}
